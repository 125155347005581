import { AuthenticatedUserFragment } from "@app/generated/graphql";

interface IIdentify extends Omit<AuthenticatedUserFragment, "_id"> {
  id: AuthenticatedUserFragment["_id"];
  createdAt: number;
}

interface IPageView {
  pageType: string;
  heading?: string;
  categoryName?: string;
}

export function identifyUser({ _id, ...details }: AuthenticatedUserFragment) {
  const { _cio } = window as any;

  _cio.identify({
    id: _id,
  } as IIdentify);
}

export function trackPageView(event: IPageView) {
  const { _cio } = window as any;

  _cio.page(document.location.href, event);
}
