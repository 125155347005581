import React from "react";
import tw, { styled } from "twin.macro";

interface ITextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, ITextareaProps>(
  ({ className, ...props }, forwardedRef) => (
    <Wrapper className={className}>
      <StyledTextarea {...props} ref={forwardedRef} />
    </Wrapper>
  )
);

export default Textarea;

//#region Styles

const Wrapper = styled.div(() => [
  tw`
    flex items-center
    bg-white
    rounded border border-gray-300
    transition-all
    p-3
    focus-within:(
      border-black
    )
  `,
]);

const StyledTextarea = styled.textarea(() => [
  tw`
    flex-grow
    bg-transparent
    text-sm    
    overflow-hidden
    focus:(
      outline-none
    )
  `,
]);

//#endregion
