import React from "react";
import tw, { css, styled } from "twin.macro";

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * An icon to display left of the input text. Must be a SVG string;
   */
  leftIcon?: JSX.Element;

  /**
   * An icon to display right of the input text. Must be a SVG string;
   */
  rightIcon?: JSX.Element;
}

const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  ({ leftIcon, rightIcon, className, ...props }, forwardedRef) => (
    <Wrapper className={className}>
      {leftIcon}
      <StyledInput {...props} ref={forwardedRef} />
      {rightIcon}
    </Wrapper>
  )
);

export default Input;

//#region Styles

export const Wrapper = styled.div(() => [
  css`
    --ionicon-stroke-width: 40;
  `,

  tw`
    flex items-center gap-2 px-3
    bg-white
    rounded border border-gray-300
    transition-all

    focus-within:(
      border-black
    )
  `,
]);

export const StyledInput = styled.input(() => [
  tw`
    appearance-none
    flex-grow h-10 w-full
    bg-transparent
    text-sm

    focus:(
      outline-none
    )
  `,
]);

//#endregion
