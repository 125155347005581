import { IonCheckbox } from "@ionic/react";
import { styled, theme } from "twin.macro";

const Checkbox = styled(IonCheckbox)`
  --border-color: ${theme`colors.gray.300`};
`;

Checkbox.defaultProps = {
  mode: "md",
};

export default Checkbox;
