import React from "react";
import tw from "twin.macro";

import { PricingInput } from "@app/generated/graphql";
import { Checkbox, Input, Toggle } from "@app/components/form";

type IToggleProps = React.ComponentPropsWithoutRef<typeof Toggle>;

const DAYS_OF_WEEK = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
] as const;

type PricingState = {
  [key in typeof DAYS_OF_WEEK[number]]: {
    enabled: boolean;
    price?: number;
  };
};

const DEFAULT_PRICING_STATE: PricingState = {
  monday: {
    enabled: true,
    price: undefined,
  },
  tuesday: {
    enabled: true,
    price: undefined,
  },
  wednesday: {
    enabled: true,
    price: undefined,
  },
  thursday: {
    enabled: true,
    price: undefined,
  },
  friday: {
    enabled: true,
    price: undefined,
  },
  saturday: {
    enabled: true,
    price: undefined,
  },
  sunday: {
    enabled: true,
    price: undefined,
  },
};

interface IPricingProps {
  value?: PricingInput;
  onChange?: (value: PricingInput) => void;

  isError?: boolean;

  errorText?: string;
}

const Pricing: React.FC<IPricingProps> = ({
  isError,
  errorText,
  value: _value,
  onChange,
  ...props
}) => {
  const [value, setValue] = React.useState(() => {
    const initialState = Object.assign({}, DEFAULT_PRICING_STATE);

    if (_value) {
      Object.entries(_value).forEach(([day, price]) => {
        // @ts-ignore
        initialState[day] = {
          enabled: !!price,
          price: price,
        };
      });
    }

    return initialState;
  });

  const [isVaryingPrice, setIsVaryingPrice] = React.useState(function checkForVaryingPrices() {
    if (!_value) return false;

    return DAYS_OF_WEEK.some((day) => _value[day] !== _value.monday);
  });

  const handleToggle: IToggleProps["onIonChange"] = (e) => {
    setIsVaryingPrice(e.detail.checked);
    setValue(DEFAULT_PRICING_STATE);
    onChange?.(parseToInput(DEFAULT_PRICING_STATE));
  };

  const handleFixedChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const newPricing = Object.assign({}, DEFAULT_PRICING_STATE);

    DAYS_OF_WEEK.forEach((day) => {
      newPricing[day] = {
        enabled: true,
        price: e.target.value ? e.target.valueAsNumber : undefined,
      };
    });

    setValue(newPricing);
    onChange?.(parseToInput(newPricing));
  };

  const handleVaryingChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const newPricing: PricingState = {
      ...value,
      [e.target.name]: {
        enabled: true,
        price: e.target.value ? e.target.valueAsNumber : undefined,
      },
    };

    setValue(newPricing);
    onChange?.(parseToInput(newPricing));
  };

  const handleToggleDay = (day: typeof DAYS_OF_WEEK[number]) => {
    const newPricing: PricingState = {
      ...value,
      [day]: {
        price: undefined,
        enabled: !value[day].enabled,
      },
    };

    setValue(newPricing);
    onChange?.(parseToInput(newPricing));
  };

  const toggle = (
    <div tw="flex justify-between gap-x-8 mb-1 pb-2 border-b border-gray-200">
      <h3 tw="text-md font-medium text-black">Pricing varies by day of the week</h3>
      <Toggle tw="flex-initial" checked={isVaryingPrice} onIonChange={handleToggle} />
    </div>
  );

  const fixedInput = (
    <div tw="flex gap-x-3">
      <div tw="flex-1 flex items-center border-b border-gray-200">
        <label htmlFor="daily rate">Daily rate</label>
      </div>

      <Input
        name="daily rate"
        leftIcon={<span tw="text-sm font-medium">$</span>}
        type="number"
        placeholder="Price / day"
        value={value["monday"].price ?? ""}
        onChange={handleFixedChange}
        tw="flex-initial w-28"
      />
    </div>
  );

  const varyingInputs = (
    <div tw="flex flex-col gap-y-2">
      {Object.entries(value).map(([day, { enabled, price }]) => (
        <div tw="flex gap-x-3 transition-opacity" css={[!enabled && tw`opacity-30`]} key={day}>
          <div tw="flex-1 flex gap-x-2 items-center border-b border-gray-200">
            {/* <Checkbox checked={enabled} onIonChange={() => handleToggleDay(day as any)} /> */}

            <label htmlFor={day} tw="capitalize">
              {day}
            </label>
          </div>

          <Input
            name={day}
            leftIcon={<span tw="text-sm font-medium">$</span>}
            type="number"
            placeholder="Price / day"
            disabled={!enabled}
            value={price ?? ""}
            onChange={handleVaryingChange}
            tw="flex-initial w-28"
          />
        </div>
      ))}
    </div>
  );

  return (
    <div tw="flex flex-col space-y-2">
      {toggle}
      {isVaryingPrice ? varyingInputs : fixedInput}
      {isError && <small tw="text-error">{errorText}</small>}
    </div>
  );
};

export default Pricing;

/** Utils */

function parseToInput(value: PricingState): PricingInput {
  const pricingInput: PricingInput = {};

  DAYS_OF_WEEK.forEach((day) => {
    pricingInput[day] =
      value[day].enabled && !Number.isNaN(value[day].price) ? value[day].price : undefined;
  });

  return pricingInput;
}
