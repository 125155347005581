import { GraphQLClient } from "graphql-request";

let apiUrl = "https://dev-api.releaseit.com.au/graphql";
switch (window.location.hostname) {
  case "releaseit.com.au":
  case "prod01.releaseit.com.au":
    apiUrl = "https://api.releaseit.com.au/graphql";
    break;

  case "staging.releaseit.com.au":
  case "stag01.releaseit.com.au":
    apiUrl = "https://staging-api.releaseit.com.au/graphql";
    break;
}

export const graphqlClient = new GraphQLClient(apiUrl, {
  credentials: "include",
});
