import tw, { styled } from "twin.macro";

interface IToolbarProps {
  type?: "sticky" | "fixed";
  pos?: "top" | "bottom";
}

// prettier-ignore
const Toolbar = styled.div<IToolbarProps>(({ type = "sticky", pos = "top"}) => [
  tw`
    --ionicon-stroke-width[45]

    z-20
    flex items-center h-16 px-4
    bg-white
    text-lg
    border-gray-300
  `,

  type === "sticky" && tw`
    sticky
  `,

  type === "fixed" && tw`
    fixed left-0 right-0
  `,

  pos === "top" && tw`
    top-0
    border-b
  `,

  pos === "bottom" && tw`
    bottom-0
    border-t
  `,
])

export default Toolbar;
