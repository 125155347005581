import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import loadable from "@loadable/component";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import queryString from "query-string";
import React from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { Route, RouteProps, useHistory } from "react-router";
import { TabsNavigation } from "./components/layout";
import { refreshToken } from "./features/auth/auth-slice";
import useAuth from "./hooks/useAuth";
import * as _cio from "./lib/customer-io";
import OnboardingCreateListing from "./pages/OnboardingCreateListing";
import { store } from "./store";
import "./styles/base.css";
import GlobalStyles from "./styles/GlobalStyles";

/** Pages */
const NotFound = loadable(() => import("./pages/NotFound"));
const ResetPasswordRequest = loadable(() => import("./pages/ResetPasswordRequest"));
const ResetPassword = loadable(() => import("./pages/ResetPassword"));
const Chat = loadable(() => import("./pages/Chat"));
const UserProfile = loadable(() => import("./pages/MyAccount/UserProfile"));
const PersonalInformation = loadable(() => import("./pages/MyAccount/PersonalInformation"));
const RentalHistory = loadable(() => import("./pages/MyAccount/RentalHistory"));
const ManageBookings = loadable(() => import("./pages/MyAccount/ManageBookings"));
const ManageListings = loadable(() => import("./pages/MyAccount/ManageListings"));
const Payments = loadable(() => import("./pages/MyAccount/Payments"));
const CreateListing = loadable(() => import("./pages/CreateListing"));
const ManageListing = loadable(() => import("./pages/ManageListing"));
const Contact = loadable(() => import("./pages/Contact"));
const Dispute = loadable(() => import("./pages/Dispute"));

/** Contexts */
export const queryClient = new QueryClient();
export const routerHistory = createBrowserHistory();

/** Init Tracking */

Sentry.init({
  dsn: "https://6703d8b0b58e4465a4a0adfb72aa557f@o1014701.ingest.sentry.io/5979936",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(routerHistory),
      tracingOrigins: [
        "localhost",
        "api.releaseit.com.au",
        "staging-api.releaseit.com.au",
        "dev-api.releaseit.com.au",
      ],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function initGA() {
  // Google Analytics
  // all this env var to be comma separated
  const trackingIds = (process.env.REACT_APP_GA_ID as string).split(/\s*,\s*/).map((trackingId) => {
    return { trackingId, gtagOptions: { user_id: store.getState().auth.user?._id } };
  });

  // the below fires a typescript error, but the documentation itself literally gives this example https://www.npmjs.com/package/react-ga4
  // it has also been tested to work so I feel it's an issue of the types not being updated in v1.1.1 (it was only just added at time of adding package)
  // @ts-ignore
  ReactGA.initialize(trackingIds);
}

// Facebook
ReactPixel.init(process.env.REACT_APP_FB_ID as string);

/** Auth Bootstrap */

store.dispatch(refreshToken()).finally(() => {
  initGA();
});

const App: React.FC = () => (
  <>
    <GlobalStyles />

    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <IonApp>
          <IonReactRouter history={routerHistory}>
            <Routes />
            <TabsNavigation className="ion-hide-lg-up" />
          </IonReactRouter>
        </IonApp>
      </QueryClientProvider>
    </Provider>
  </>
);

function ProtectedRoute({
  component: Component,
  ...props
}: RouteProps & { component: React.ComponentType }) {
  const { user, loading, loginWithRedirect } = useAuth();

  if (loading) {
    return null;
  }

  if (!user) {
    loginWithRedirect();
    return null;
  }

  return <Route {...props} render={(params) => <Component {...params} />} />;
}

declare global {
  interface Window {
    _tfa: any;
  }
}

const Routes: React.FC = () => {
  const history = useHistory();

  // Track page views
  React.useEffect(() => {
    const unsubscribe = history.listen((location) => {
      _cio.trackPageView({ pageType: location.pathname.substr(1) });
      ReactPixel.pageView();
      window._tfa.push({ notify: "event", name: "page_view" });

      // I tried to use useLocation() here but React it won't allow it
      const { q } = queryString.parse(window.location.search);
      if (q) {
        ReactGA.event("view_search_results", { search_term: q });
      } else {
        ReactGA.send("pageview");
      }
    });

    return unsubscribe;
  });

  return (
    <IonRouterOutlet animated={false} id="main">
      {/* Paths */}
      <Route exact path="/reset-password" component={ResetPasswordRequest} />
      <Route path="/reset-password/:resetToken" component={ResetPassword} />
      <Route exact path="/contact" component={Contact} />
      <ProtectedRoute exact path="/chat" component={Chat} />
      <ProtectedRoute path="/chat/:listingId" component={Chat} />
      <ProtectedRoute exact path="/my-account/profile" component={UserProfile} />
      <ProtectedRoute exact path="/my-account/personal-info" component={PersonalInformation} />
      <ProtectedRoute exact path="/my-account/rental-history" component={RentalHistory} />
      <ProtectedRoute exact path="/my-account/manage-bookings" component={ManageBookings} />
      <ProtectedRoute exact path="/my-account/manage-listings" component={ManageListings} />
      <ProtectedRoute exact path="/my-account/payments" component={Payments} />
      <ProtectedRoute exact path="/onboarding/create-listing" component={OnboardingCreateListing} />
      <ProtectedRoute exact path="/create-listing" component={CreateListing} />
      <ProtectedRoute exact path="/manage-listing/:id" component={ManageListing} />
      <ProtectedRoute path="/dispute/:bookingId" component={Dispute} />

      {/* Redirects */}
      <Route component={NotFound} />
    </IonRouterOutlet>
  );
};

export default App;
