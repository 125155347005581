import React from "react";
import { Wrapper } from "../input";
import tw, { styled } from "twin.macro";

/**
 * TODO: Research styles for native.
 *
 * What do we want this to look like on native?
 * Do we want web dropdown on web, and action sheet on native?
 * Ionic doesn't seem to let us do the above and always "mimics" a native looking select.
 */

interface ISelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {}

const Select = React.forwardRef<HTMLSelectElement, ISelectProps>(
  ({ children, className, ...props }, forwardedRef) => (
    <Wrapper className={className}>
      <StyledSelect {...props} ref={forwardedRef}>
        {children}
      </StyledSelect>
    </Wrapper>
  )
);

export default Select;

//#region Styles

const StyledSelect = styled.select(() => [
  tw`
    appearance-none
    bg-white
    flex-grow h-10 pr-4
    text-sm
    outline-none
  `,
]);

//#endregion
