import { IonIcon } from "@ionic/react";
import { Popover, PopoverContent, PopoverTrigger } from "@radix-ui/react-popover";
import { gql } from "graphql-request";
import React from "react";
import { Link } from "react-router-dom";
import { menuOutline, chevronForwardOutline } from "ionicons/icons";
import tw from "twin.macro";

import { NavigationTopCategory, useNavigationQuery } from "@app/generated/graphql";

import classes from "./CategoryMegaMenu.module.css";

export const NAVIGATION_QUERY = gql`
  query Navigation {
    Navigation {
      categories {
        title
        href
        featured {
          title
          href
        }
        popularBrands {
          title
          href
        }
        children {
          ...NavigationCategoryRecursive
          children {
            ...NavigationCategoryRecursive
            children {
              ...NavigationCategoryRecursive
            }
          }
        }
      }
    }
  }

  fragment NavigationCategoryRecursive on NavigationCategory {
    title
    href
    featured {
      title
      href
    }
  }
`;

export function CategoryMegaMenu() {
  const [isOpen, setIsOpen] = React.useState(false);
  const rootRef = React.useRef<HTMLDivElement>(null);

  const query = useNavigationQuery({}, { staleTime: 30 * 60 * 1000 });

  function TopLevelNavItem({ category }: { category: NavigationTopCategory }) {
    const [isInnerOpen, setIsInnerOpen] = React.useState(false);

    return (
      <div onMouseEnter={() => setIsInnerOpen(true)} onMouseLeave={() => setIsInnerOpen(false)}>
        <Popover open={isInnerOpen} onOpenChange={setIsInnerOpen}>
          <PopoverTrigger tw="inline-flex items-center justify-between h-12 w-full pl-6 pr-3 bg-off-white text-black hover:bg-gray-100">
            {category.title} <IonIcon icon={chevronForwardOutline} tw="text-xl" />
          </PopoverTrigger>

          <PopoverContent
            side="right"
            align="start"
            portalled={false}
            onOpenAutoFocus={(e) => e.preventDefault()}
            onCloseAutoFocus={(e) => e.preventDefault()}
            onClick={() => setIsOpen(false)}
          >
            <CategorySubMenu category={category} />
          </PopoverContent>
        </Popover>
      </div>
    );
  }

  return (
    <div
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      tw="justify-self-start"
    >
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger tw="h-full inline-flex gap-4 items-center">
          <IonIcon icon={menuOutline} tw="text-2xl" />
          Categories
        </PopoverTrigger>

        <PopoverContent
          ref={rootRef}
          align="start"
          onOpenAutoFocus={(e) => {
            e.preventDefault();
            rootRef.current?.focus();
          }}
          onCloseAutoFocus={(e) => e.preventDefault()}
          className={classes.root}
          tw="w-[270px] bg-off-white shadow-lg focus:outline-none"
        >
          {query.data?.Navigation.categories.map((category) => (
            <TopLevelNavItem key={category.title} category={category as any} />
          ))}
        </PopoverContent>
      </Popover>
    </div>
  );
}

interface CategorySubMenuProps {
  category: NavigationTopCategory;
}

function CategorySubMenu({ category }: CategorySubMenuProps) {
  return (
    <div tw="flex h-[524px] w-[calc(85vw - 270px)] max-w-4xl shadow-lg overflow-auto">
      {/* Subcategories */}
      <div tw="w-3/4 flex-grow p-6 bg-white">
        <SubMenuHeading category={category} />

        <ul tw="columns[150px 4] gap-10 py-6">
          {category.children.map((subcategory) => (
            <NestedSubMenu
              key={subcategory.title}
              heading={subcategory.title}
              href={subcategory.href}
              items={subcategory.children}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

interface SubMenuHeadingProps {
  category: NavigationTopCategory;
}

function SubMenuHeading({ category }: SubMenuHeadingProps) {
  return (
    <div tw="flex items-baseline gap-x-4">
      <h2 tw="text-2xl font-medium">{category.title}</h2>

      <a href={`/hire/${category.href}`} tw="text-base text-gray-400 underline">
        View All
      </a>
    </div>
  );
}

interface NestedSubMenuProps {
  heading: string;
  href: string;
  items: { title: string; href: string }[];
}

function NestedSubMenu({ heading, href, items }: NestedSubMenuProps) {
  return (
    <li tw="mb-5 space-y-2 break-inside[avoid]">
      <a
        href={`/hire/${href}`}
        css={[
          tw`block pb-2 text-base font-medium text-black hover:text-mid-green no-underline`,
          items.length > 0 && tw`border-b`,
        ]}
      >
        {heading}
      </a>

      {items && (
        <ul tw="text-sm">
          {items.map((item) => (
            <li key={item.title} tw="py-1.5 truncate">
              <a href={`/hire/${item.href}`} tw="text-gray-800 hover:text-mid-green no-underline">
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
}
