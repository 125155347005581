import React from "react";
import ReactSelect, { GroupTypeBase, Props as ReactSelectProps, StylesConfig } from "react-select";
import ReactAsyncSelect, { Props as ReactAsyncSelectProps } from "react-select/async";
import ReactCreatableSelect, { Props as ReactCreatableSelectProps } from "react-select/creatable";
import tw from "twin.macro";

import { ILabelledInputProps, styles as labelledStylesInput } from "../labelled-input";

const styles = {
  ...labelledStylesInput,

  /** Containers */

  valueContainer: tw`
    h-full pt-5
    text-md
  `,

  singleValue: tw`
    text-md
    transform-gpu translate-y-0
  `,

  /** Input */

  input: {
    ...labelledStylesInput.input,
    ...tw`
      p-0
      shadow-none

      hover:border-gray-300
    `,
  },

  focusInput: {
    ...labelledStylesInput.focusInput,
    ...tw`
      hover:border-primary
    `,
  },

  invalidInput: {
    ...labelledStylesInput.invalidInput,
    ...tw`
      hover:border-error
    `,
  },

  placeholder: tw`
    text-gray-400 text-md
    transform-gpu translate-y-0
  `,

  errorPlaceholder: tw`
    text-error
  `,

  /** Icons */

  icon: tw`
    text-gray-800
  `,
};

function getStyleProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupTypeBase<Option> = GroupTypeBase<Option>
>({ isError }: IComboboxProps): StylesConfig<Option, IsMulti, Group> {
  return {
    control: (provided, state) => ({
      ...provided,
      ...styles.input,
      ...(state.isFocused && styles.focusInput),
      ...(isError && styles.invalidInput),
      ...(state.isDisabled && styles.disabledInput),
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      ...styles.icon,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      ...styles.icon,
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      ...styles.icon,
    }),
    placeholder: (provided) => ({
      ...provided,
      ...styles.placeholder,
      ...(isError && styles.errorPlaceholder),
    }),
    valueContainer: (provided) => ({
      ...provided,
      ...styles.valueContainer,
    }),
    singleValue: (provided) => ({
      ...provided,
      ...styles.singleValue,
    }),
    menu: (provided) => ({
      ...provided,
      ...tw`z-10`,
    }),
    option: (provided, state) => ({
      ...provided,
      ...tw`hover:bg-pastel-green`,
      ...(state.isFocused && tw`bg-pastel-green`),
      ...(state.isSelected && tw`bg-primary! text-black`),
    }),
  };
}

type IComboboxProps = Pick<
  ILabelledInputProps,
  "label" | "assistiveText" | "isError" | "errorText" | "placeholder"
> & {};

/**
 * Combobox
 */

export const Combobox = React.forwardRef(function Combobox<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupTypeBase<Option> = GroupTypeBase<Option>
>(
  props: ReactSelectProps<Option, IsMulti, Group> & IComboboxProps,
  forwardedRef: React.Ref<ReactSelect<Option, IsMulti, Group>>
) {
  return (
    <Wrapper {...props}>
      <ReactSelect
        placeholder="Please select an option"
        {...props}
        styles={getStyleProps<Option, IsMulti, Group>(props)}
        ref={forwardedRef}
      />
    </Wrapper>
  );
}) as <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupTypeBase<Option> = GroupTypeBase<Option>
>(
  props: ReactSelectProps<Option, IsMulti, Group> & IComboboxProps
) => JSX.Element;

/**
 * Async Combobox
 */

export const AsyncCombobox = React.forwardRef(function AsyncCombobox<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupTypeBase<Option> = GroupTypeBase<Option>
>(
  props: ReactAsyncSelectProps<Option, IsMulti, Group> & IComboboxProps,
  forwardedRef: React.Ref<ReactAsyncSelect<Option, IsMulti, Group>>
) {
  return (
    <Wrapper {...props}>
      <ReactAsyncSelect
        placeholder={props.placeholder || "Please select an option"}
        {...props}
        styles={getStyleProps<Option, IsMulti, Group>(props)}
        ref={forwardedRef}
      />
    </Wrapper>
  );
}) as <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupTypeBase<Option> = GroupTypeBase<Option>
>(
  props: ReactAsyncSelectProps<Option, IsMulti, Group> & IComboboxProps
) => JSX.Element;

/**
 * Creatable Combobox
 */

export const CreatableCombobox = React.forwardRef(function CreatableCombobox<
  Option,
  IsMulti extends boolean = false
>(
  props: ReactCreatableSelectProps<Option, IsMulti> & IComboboxProps,
  forwardedRef: React.Ref<ReactCreatableSelect<Option, IsMulti>>
) {
  return (
    <Wrapper {...props}>
      <ReactCreatableSelect
        placeholder="Please select an option"
        {...props}
        styles={getStyleProps<Option, IsMulti>(props)}
        ref={forwardedRef}
      />
    </Wrapper>
  );
}) as <Option, IsMulti extends boolean = false>(
  props: ReactCreatableSelectProps<Option, IsMulti> & IComboboxProps
) => JSX.Element;

/**
 * Wrapper
 */

const Wrapper: React.FC<IComboboxProps> = ({ children, ...props }) => (
  <div tw="relative">
    <label
      css={[styles.label, styles.activeLabel, props.isError && styles.invalidLabel]}
      tw="top-2.5 left-2.5 z-index[1]"
    >
      {props.label}
    </label>

    {children}

    {!props.isError && <small css={[styles.assistiveText]}>{props.assistiveText}</small>}
    {props.isError && <small css={[styles.errorText]}>{props.errorText}</small>}
  </div>
);
