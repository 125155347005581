import React from "react";
import mergeRefs from "react-merge-refs";
import tw from "twin.macro";

export const styles = {
  /** Input */

  input: tw`
    appearance-none
    w-full h-14
    px-3 pt-4
    bg-white
    text-md
    rounded border border-gray-300
    outline-none
    transition-colors
  `,

  focusInput: tw`
    bg-pastel-green
    border-primary
  `,

  disabledInput: tw`
    rounded-none border-0 border-b
  `,

  invalidInput: tw`
    bg-pastel-pink
    border-error

    placeholder:text-error
  `,

  withIconInput: tw`
    pl-9
  `,

  /** Label */

  label: tw`
    absolute left-3 top-1/2 transform-gpu -translate-y-1/2
    text-md text-gray-400
    pointer-events-none
    transition-all
  `,

  activeLabel: tw`
    top-2 translate-y-0
    text-xs text-black font-medium uppercase
  `,

  disabledLabel: tw`
    text-black
  `,

  invalidLabel: tw`
    text-error
  `,

  /** Icon */

  icon: tw`
    --ionicon-stroke-width[40]

    absolute right-3 top-1/2 transform-gpu -translate-y-1/2
    h-5 w-5
    text-xl
  `,

  /** Assistive Text */

  assistiveText: tw`
    text-sm text-gray-400 font-normal
  `,

  /** Error Text */

  errorText: tw`
    text-sm text-red font-normal
  `,
};

export interface ILabelledInputProps extends React.ComponentPropsWithoutRef<"input"> {
  /**
   * Label to display above the input.
   */
  label: string;

  /**
   * Text to display below the input.
   */
  assistiveText?: string;

  /**
   * Whether the input is currently invalid.
   */
  isError?: boolean;

  /**
   * Text to display below the input when invalid.
   */
  errorText?: string;

  /**
   * Icon to display left of the input.
   */
  icon?: React.ReactNode;

  wrapperProps?: React.ComponentPropsWithoutRef<"div">;
}

export const LabelledInput = React.forwardRef<HTMLInputElement, ILabelledInputProps>(
  ({ label, assistiveText, isError, errorText, icon, wrapperProps, ...props }, forwardedRef) => {
    const internalRef = React.useRef<HTMLInputElement>(null);

    const [value, setValue] = React.useState(props.value || props.defaultValue);
    const [hasFocus, setHasFocus] = React.useState(false);
    const isActive = value || hasFocus;

    React.useEffect(function checkForUncontrolledInitialValue() {
      if (internalRef.current?.value) {
        setValue(internalRef.current.value);
      }
    }, []);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      setValue(e.target.value);
    };

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
      setHasFocus(e.type === "focus");
    };

    return (
      <div {...wrapperProps}>
        <div tw="relative">
          <label
            css={[
              styles.label,
              props.disabled && styles.disabledLabel,
              isActive && styles.activeLabel,
              isError && styles.invalidLabel,
            ]}
          >
            {label}
          </label>

          <input
            {...props}
            onChange={(e) => {
              handleChange(e);
              props.onChange?.(e);
            }}
            onFocus={(e) => {
              handleFocus(e);
              props.onFocus?.(e);
            }}
            onBlur={(e) => {
              handleFocus(e);
              props.onBlur?.(e);
            }}
            css={[
              styles.input,
              props.disabled && styles.disabledInput,
              hasFocus && styles.focusInput,
              isError && styles.invalidInput,
            ]}
            ref={mergeRefs([internalRef, forwardedRef])}
          />

          {icon && <div css={[styles.icon]}>{icon}</div>}
        </div>

        {!isError && <small css={[styles.assistiveText]}>{assistiveText}</small>}
        {isError && <small css={[styles.errorText]}>{errorText}</small>}
      </div>
    );
  }
);

export default LabelledInput;
