import { css } from "twin.macro";

/**
 * Helper function for mixing CSS Selectors with Styled Components prop-based styles.
 * Allows for styles to be applied when either a CSS Selector matches, or a prop value is truthy.
 * 
 * @example
 * 
 * ```tsx
 * css`
    ${Wrapper}:focus-within &,
    ${when(props.isActive)} {
      // These styles will apply when either Wrapper has focus within, or isActive is true.
    }
    `,
 * ```
 */
export function when(value?: boolean, specificity: number = 1) {
  return value ? "&".repeat(specificity) : "__ignored";
}

/**
 * Helper function for increasing the specificity of styles by repeating the "&" character.
 * 
 * @example 
 * 
 * ```tsx
 * increaseSpecificityBy(2, tw`
    // These styles will have increased specificity.
  `),
 * ```
 */
export function increaseSpecificityBy(count: number, styles: any) {
  return css`
    ${"&".repeat(count)} {
      ${styles}
    }
  `;
}
