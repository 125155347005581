import React from "react";
import { Props as ReactSelectProps } from "react-select";
import ReactAsyncSelect, { Props as ReactAsyncSelectProps } from "react-select/async";
import tw from "twin.macro";

import { ILabelledInputProps, styles as labelledStylesInput } from "../labelled-input";

const styles = {
  ...labelledStylesInput,

  /** Containers */

  valueContainer: tw`
    h-full
    text-sm
    hover:cursor-auto
  `,

  singleValue: tw`
    text-sm
  `,

  /** Input */
  input: {
    ...tw`
      p-0
      flex-grow h-10
      shadow-none
      flex items-center gap-2 px-8
      hover:border-gray-300
    `,
  },

  focusInput: {
    ...tw`
      border-gray-300
      hover:border-primary
    `,
  },

  invalidInput: {
    ...tw`
      hover:border-error
    `,
  },

  placeholder: tw`
    text-gray-400 text-sm
  `,

  errorPlaceholder: tw`
    text-error
  `,

  /** Icons */
  icon: tw`
    text-gray-800
  `,
  /** Remove Indicator */
  clear: tw`
    hidden
  `,
};

function getStyleProps({ isError }: IComboboxProps): ReactSelectProps["styles"] {
  return {
    control: (provided, state) => ({
      ...provided,
      ...styles.input,
      ...(state.isFocused && styles.focusInput),
      ...(isError && styles.invalidInput),
      ...(state.isDisabled && styles.disabledInput),
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      ...styles.clear,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      ...styles.clear,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      ...styles.clear,
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      ...styles.icon,
    }),
    placeholder: (provided) => ({
      ...provided,
      ...styles.placeholder,
      ...(isError && styles.errorPlaceholder),
    }),
    valueContainer: (provided) => ({
      ...provided,
      ...styles.valueContainer,
    }),
    singleValue: (provided) => ({
      ...provided,
      ...styles.singleValue,
    }),
    menu: (provided) => ({
      ...provided,
      ...tw`z-10`,
    }),
    option: (provided, state) => ({
      ...provided,
      ...tw`hover:bg-pastel-green`,
      ...(state.isFocused && tw`bg-pastel-green`),
      ...(state.isSelected && tw`bg-primary! text-black`),
    }),
  };
}

type IComboboxProps = Pick<
  ILabelledInputProps,
  "label" | "assistiveText" | "isError" | "errorText"
> & {};

const Autocomplete: React.FC<
  ReactAsyncSelectProps<{ label: string; value: string }, false> & IComboboxProps
> = React.forwardRef<ReactAsyncSelect<{ label: string; value: string }>, any>(
  ({ leftIcon, rightIcon, ...props }, forwardedRef) => (
    <Wrapper {...props}>
      <span tw="absolute z-10 mt-3 ml-3">{leftIcon}</span>
      <ReactAsyncSelect
        placeholder="Please select an option"
        {...props}
        styles={getStyleProps(props)}
        ref={forwardedRef}
      />
      <span tw="absolute z-10 mt-3 mr-3 right-0 top-0">{rightIcon}</span>
    </Wrapper>
  )
);

export default Autocomplete;

const Wrapper: React.FC<IComboboxProps> = ({ children, ...props }) => (
  <div tw="relative">{children}</div>
);
