import { IonContent, IonPage } from "@ionic/react";

import { DesktopHeader, Footer } from "@app/components/layout";

import { styled } from "twin.macro";

export function withDefaultLayout(View: React.FC): React.FC {
  return () => (
    <IonPage>
      {/* <MobileHeader className="ion-hide-lg-up" /> */}

      <ContentWrapper>
        <DesktopHeader className="ion-hide-lg-down" />

        <div tw="flex-grow">
          <View />
        </div>

        <Footer className="ion-hide-lg-downoff" />
      </ContentWrapper>
    </IonPage>
  );
}

//#region Styles

const ContentWrapper = styled(IonContent)`
  &::part(scroll) {
    display: flex;
    flex-direction: column;
  }
`;

//#endregion
