import React from "react";
import { IonIcon, IonProgressBar, useIonToast } from "@ionic/react";
import ReactDropzone, {
  IDropzoneProps as IReactDropzoneProps,
  StatusValue,
} from "react-dropzone-uploader";
import tw from "twin.macro";

import { addCircleOutline, closeOutline } from "ionicons/icons";
import { ILabelledInputProps } from "../labelled-input";

export const DZ_MAX_IMAGES = 5;
export const DZ_MAX_SIZE_MB = 5;
export const DZ_ERROR_MAPPINGS: { [key in StatusValue]?: string } = {
  rejected_max_files: `Too many images selected (${DZ_MAX_IMAGES} images max).`,
  error_file_size: `One or more of the images are too large (${DZ_MAX_SIZE_MB}MB limit).`,
};

export type IDropzoneProps = Partial<
  Omit<IReactDropzoneProps, "LayoutComponent" | "InputComponent" | "PreviewComponent">
> &
  Pick<ILabelledInputProps, "isError" | "errorText"> & {};

const Dropzone = React.forwardRef<ReactDropzone, IDropzoneProps>(
  ({ onChangeStatus, isError, errorText, ...props }, forwardedRef) => {
    const [presentToast] = useIonToast();

    return (
      <div>
        <ReactDropzone
          maxFiles={DZ_MAX_IMAGES}
          maxSizeBytes={DZ_MAX_SIZE_MB * Math.pow(2, 20)}
          {...props}
          onChangeStatus={(file, status, allFiles) => {
            if (status in DZ_ERROR_MAPPINGS) {
              file.remove?.();
              presentToast({
                color: "danger",
                message: DZ_ERROR_MAPPINGS[status],
                duration: 5000,
              });
              return;
            }

            onChangeStatus?.(file, status, allFiles);
          }}
          LayoutComponent={LayoutComponent}
          InputComponent={InputComponent}
          PreviewComponent={PreviewComponent}
          ref={forwardedRef}
        />

        {isError && <small tw="text-error">{errorText}</small>}
      </div>
    );
  }
);

export default Dropzone;

const LayoutComponent: IReactDropzoneProps["LayoutComponent"] = ({
  previews,
  input,
  dropzoneProps,
  files,
}) => (
  <div {...dropzoneProps} tw="space-y-4 select-none">
    <div>
      <h3 tw="text-md font-medium text-black mb-1">
        {files.length > 0
          ? `Uploaded ${files.length} / ${DZ_MAX_IMAGES} photos.`
          : `You can upload up to ${DZ_MAX_IMAGES} photos. We recommend landscape orientation.`}
      </h3>

      {input}
    </div>

    {files.length > 0 && <div tw="flex gap-x-4 pb-3">{previews}</div>}
  </div>
);

const InputComponent: IReactDropzoneProps["InputComponent"] = ({
  accept,
  multiple,
  disabled,
  getFilesFromEvent,
  onFiles,
  extra: { active },
}) => (
  <label
    tw="w-full h-44 flex flex-col bg-white items-center justify-center rounded-lg border border-gray-300 transition-colors"
    css={[active && tw`border-primary bg-pastel-green`]}
  >
    <IonIcon icon={addCircleOutline} tw="text-mid-green text-3xl mb-1" />
    <h3 tw="text-base font-medium text-black mb-3">Add photos</h3>
    <p tw="text-md text-gray-400">Click to browse or drag and drop.</p>
    <p tw="text-md text-gray-400">Accepts image files up to {DZ_MAX_SIZE_MB}Mb.</p>

    <input
      type="file"
      accept={accept}
      multiple={multiple}
      disabled={disabled}
      onChange={async (e) => {
        const target = e.target;
        const chosenFiles = await getFilesFromEvent(e);
        onFiles(chosenFiles);
        //@ts-ignore
        target.value = null;
      }}
      tw="hidden"
    />
  </label>
);

const PreviewComponent: IReactDropzoneProps["PreviewComponent"] = ({
  fileWithMeta: { remove },
  meta: { name, previewUrl, percent = 0 },
}) => (
  <div tw="relative">
    <img src={previewUrl} alt={name} tw="w-16 h-16 object-cover rounded-md shadow lg:(w-24 h-24)" />

    {percent < 100 && <IonProgressBar value={percent} tw="inset-x-0 bottom-0 absolute" />}

    <IonIcon
      icon={closeOutline}
      tw="absolute cursor-pointer bottom-0 right-0 transform-gpu translate-x-1/3 translate-y-1/3 shadow bg-off-white rounded-full p-1 hover:(text-red) "
      onClick={() => remove()}
    />
  </div>
);
