import React, { useMemo } from "react";
import { IonIcon, IonLabel, IonTabBar, IonTabButton } from "@ionic/react";

/* Icons */
import {
  addCircleOutline,
  compassOutline,
  ellipsisHorizontal,
  menuOutline,
  searchOutline,
  personOutline,
  chatboxOutline,
  logInOutline,
  logOutOutline,
  newspaperOutline,
} from "ionicons/icons";
import { Link } from "react-router-dom";

/* Styles */
import tw, { styled, theme } from "twin.macro";

/* Types */
import { INavItem } from "@app/typings";
import useAuth from "@app/hooks/useAuth";
import { ButtonLink } from "@app/components/form";
import { useGetNavigationQuery } from "@app/generated/graphql";

interface IPromoLink {
  title: string;
  href: string;
}

const MOBILE_NAV_ITEMS: INavItem[] = [
  {
    label: "Explore",
    destination: "#",
    icon: compassOutline,
  },
  {
    label: "Categories",
    destination: "/category",
    icon: menuOutline,
  },
  {
    label: "Search",
    destination: "#",
    icon: searchOutline,
  },
  {
    label: "List Item",
    destination: "/create-listing",
    icon: addCircleOutline,
  },
  {
    label: "More",
    destination: "#",
    icon: ellipsisHorizontal,
  },
];

const TabsNavigation: React.FC<{ className?: string }> = ({ className }) => {
  const { user, loginWithRedirect, logout } = useAuth();
  const query = useGetNavigationQuery({}, { staleTime: 1000 * 60 * 30 });
  const promoLinks: IPromoLink[] = useMemo(() => {
    return (
      query.data?.Navigation.promoLinks.map((pl) => ({ title: pl.title, href: pl.href })) || []
    );
  }, [query.data]);

  const handleTabChange = (e: CustomEvent<any>) => {
    if (e.detail.tab === "Explore") {
      window.location.replace("/");
    }

    if (e.detail.tab === "Search") {
      window.location.replace("/search");
    }

    //show the context menu only when the more tab is clicked
    if (e.detail.tab === "More") {
      setContextMenu(!contextMenu);
    } else {
      setContextMenu(false);
    }
  };
  const [contextMenu, setContextMenu] = React.useState(false);

  if (query.isLoading) {
    return null;
  }

  return (
    <div id="mobile-navbar" tw="relative">
      <StyledTabBar tw="relative" className={className} onIonTabsWillChange={handleTabChange}>
        {MOBILE_NAV_ITEMS.map((navItem) => (
          <IonTabButton
            tab={navItem.label}
            href={navItem.destination as string}
            key={navItem.label}
          >
            <IonIcon icon={navItem.icon} />
            <IonLabel>{navItem.label}</IonLabel>
          </IonTabButton>
        ))}
      </StyledTabBar>
      {contextMenu && (
        <ListWrapper>
          {promoLinks.map((pl) => (
            <ListItem
              href={pl.href}
              icon={newspaperOutline}
              label={pl.title}
              removeContextMenu={setContextMenu}
            />
          ))}
          {user ? (
            <>
              <ListItem
                href="/my-account"
                icon={personOutline}
                label="My Account"
                removeContextMenu={setContextMenu}
              />
              <ListItem
                href="/chat"
                icon={chatboxOutline}
                label="Messages"
                removeContextMenu={setContextMenu}
              />
              <ListItem
                onClick={() => logout()}
                icon={logOutOutline}
                label="Sign out"
                removeContextMenu={setContextMenu}
              />
            </>
          ) : (
            <ListItem
              onClick={() => loginWithRedirect()}
              icon={logInOutline}
              label="Sign in"
              removeContextMenu={setContextMenu}
            />
          )}
        </ListWrapper>
      )}
    </div>
  );
};

//#region Internal Styles

const ListItem = (props: any) => {
  if (props.href) {
    return (
      <li>
        <a href={props.href} onClick={() => props.removeContextMenu(false)}>
          <IonIcon icon={props.icon} size="small" />
          <IonLabel>{props.label}</IonLabel>
        </a>
      </li>
    );
  }
  if (props.onClick) {
    return (
      <li>
        <ButtonLink
          onClick={() => {
            props.removeContextMenu(false);
            props.onClick();
          }}
        >
          <IonIcon icon={props.icon} size="small" />
          <IonLabel>{props.label}</IonLabel>
        </ButtonLink>
      </li>
    );
  }
  return <></>;
};

const ListWrapper = styled.ul`
  ${tw`absolute bottom-16 right-6 w-56 rounded-md 
  bg-white ring-1 ring-gray-200 ring-opacity-5`}
  li {
    ${tw`flex mx-3 py-2.5 border-b border-gray-200`}
    &:last-child {
      ${tw`border-b-0`}
    }
  }
  a,
  button {
    ${tw`flex gap-x-2 items-center justify-center
      text-md text-black no-underline hover:text-primary`}
  }
  ion-icon {
    ${tw`-mt-0.5`}
  }
`;

const StyledTabBar = styled(IonTabBar)`
  --color: ${theme`colors.gray.800`};
  --color-selected: ${theme`colors.mid-green`};

  ${tw`border-t border-gray-200`}
`;

//#endregion

export default TabsNavigation;
