import { IonRadio } from "@ionic/react";
import tw, { styled } from "twin.macro";

type IonRadioProps = React.ComponentProps<typeof IonRadio>;

export interface IRadioProps extends IonRadioProps {
  /**
   * Sets the color of the Radio.
   *
   * @note `light` should be used as a secondary colour.
   */
  color?: "primary" | "light" | "dark" | "medium" | "off-white" | "success" | "warning" | "danger";
}

const Radio: React.FC<IRadioProps> = ({ className, children, name, ...props }) => (
  <div tw="flex items-center gap-2">
    <StyledRadio name={name} {...props} />
    <label htmlFor={name}>{children}</label>
  </div>
);

const StyledRadio = styled(IonRadio)`
  &.radio-checked::part(container) {
    ${tw`bg-primary`}
  }

  &::part(mark) {
    ${tw`bg-black`}
  }
`;

export default Radio;
