import { Pricing } from "@app/generated/graphql";

/**
 * Conversions
 */

export const dollarToCents = (value: number) => value * 100;

export function pricingAsCents(pricing: Pricing) {
  const newPricing: Pricing = {};

  for (const [day, price] of Object.entries(pricing)) {
    newPricing[day as keyof Pricing] = price && dollarToCents(price);
  }

  return newPricing;
}

export const centsToDollars = (value: number) => value / 100;

export function pricingAsDollars(pricing: Pricing) {
  const newPricing: Pricing = {};

  for (const [day, price] of Object.entries(pricing)) {
    newPricing[day as keyof Pricing] = price ? centsToDollars(price) : undefined;
  }

  return newPricing;
}

/**
 * Misc
 */

export function getStartingPrice(pricing: Pricing) {
  const prices = Object.values(pricing);

  let lowestPrice = prices.find((p) => !!p);

  if (!lowestPrice) return 0;

  for (const price of prices) {
    if (price && price < lowestPrice) {
      lowestPrice = price;
    }
  }

  return lowestPrice;
}

export function isVaryingPrice(pricing: Pricing) {
  const prices = Object.values(pricing);

  return prices.some((price) => price !== prices[0]);
}
