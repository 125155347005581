import { useAppDispatch, useAppSelector } from "@app/store";
import * as authSlice from "@app/features/auth/auth-slice";

function loginWithRedirect() {
  window.location.assign(`/login?callbackUrl=${encodeURIComponent(window.location.href)}`);
}

function useAuth() {
  const user = useAppSelector(authSlice.selectCurrentUser);
  const accessToken = useAppSelector(authSlice.selectAccessToken);
  const loading = useAppSelector(authSlice.selectLoading);

  /** Methods */

  const dispatch = useAppDispatch();

  const logout = () => dispatch(authSlice.logout());

  return {
    loginWithRedirect,
    user,
    accessToken,
    loading,
    logout,
  };
}

export default useAuth;
