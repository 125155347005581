import React from "react";
import { IonIcon } from "@ionic/react";
import { Link } from "react-router-dom";
import { SubmitHandler, useFieldArray, useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import debounce from "lodash.debounce";
import startCase from "lodash/startCase";
import "twin.macro";

import { formatCurrency } from "@app/utils/formatters";
import {
  AttributeType,
  Condition,
  GetAvailableCategoriesQuery,
  ListingLocation,
  useGetAvailableCategoriesQuery,
  useGetCategoryAttributesQuery,
  useLocalityAutocompleteQuery,
  useRequestImageTokenQuery,
} from "@app/generated/graphql";
import {
  Button,
  Dropzone,
  Input,
  LabelledInput,
  Textarea,
  IDropzoneProps,
  Combobox,
  AsyncCombobox,
  CreatableCombobox,
} from "@app/components/form";
import { Pricing } from "@app/components/listing";

import { addCircleOutline, closeCircleOutline } from "ionicons/icons";
import { gql } from "graphql-request";

/**
 * 1. Mount empty form
 * 2. Fetch categories
 *
 * events
 *
 * on category change
 * - 1. fetch attributes for category
 * - 2. clear previous attribute fields if any
 * - 3. render attribute fields
 */

export const GET_AVAILABLE_CATEGORIES_QUERY = gql`
  query GetAvailableCategories {
    AvailableCategories: AvailableCategories2(depth: 3) {
      categoryId
      categoryName
      path
      depth
    }
  }
`;

export const GET_CATEGORY_ATTRIBUTES_QUERY = gql`
  query GetCategoryAttributes($categoryId: String!) {
    CategoryAttributes(categoryId: $categoryId) {
      attributeId
      attributeType
      attributeName
      categoryIds
      selectOptions
      # required
    }
  }
`;

export const LOCALITY_AUTOCOMPLETE_QUERY = gql`
  query LocalityAutocomplete($localityPartial: String!) {
    LocalityAutocomplete(localityPartial: $localityPartial) {
      postcode
      locality
      state
      latitude
      longitude
    }
  }
`;

export const REQUEST_IMAGE_TOKEN_QUERY = gql`
  query RequestImageToken($qty: Int) {
    RequestImageToken(qty: $qty) {
      token
    }
  }
`;

const CONDITION_OPTIONS = [
  { label: "Like New", value: Condition.Likenew },
  { label: "Good", value: Condition.Good },
  { label: "Fair", value: Condition.Fair },
] as const;

/** Schema */

const AttributeSchema = z.object({
  attributeId: z.string(),
  valueString: z.string().transform(startCase).optional(),
  valueInt: z.number().optional(),
  valueFloat: z.number().optional(),
  valueBoolean: z.boolean().optional(),
  valueStringArray: z.array(z.string()).optional(),
});

const LocationSchema = z.object({
  postcode: z.string(),
  locality: z.string(),
  state: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  national: z.boolean().default(false),
});

const ItemDescriptionSchema = z.object({
  title: z.string().min(3),
  brand: z.string().min(3).transform(startCase),
  images: z
    .array(z.string())
    .min(1, "You must upload at least 1 image")
    .max(5, "You can only upload 5 images at a time")
    .default([]),
  location: LocationSchema,
  category: z.object({
    categoryId: z.string(),
    categoryName: z.string(),
  }),
  condition: z.nativeEnum(Condition),
  description: z.string().min(10).max(1000),
  rules: z.array(z.object({ value: z.string().min(3).max(120) })),
  keywords: z.array(z.string().min(2)).default([]),
  attributes: z.array(AttributeSchema).default([]),
});

const PriceSchema = z.number().int("Please provide a whole number").positive().optional();

export const PricingSchema = z.object({
  pricing: z
    .object({
      monday: PriceSchema,
      tuesday: PriceSchema,
      wednesday: PriceSchema,
      thursday: PriceSchema,
      friday: PriceSchema,
      saturday: PriceSchema,
      sunday: PriceSchema,
    })
    .refine(
      (val) => Object.values(val).filter((price) => price !== undefined).length === 7,
      "You must specify pricing for every day"
    ),
  bondPrice: z.number().int("Please provide a whole number").min(0),
});

const CreateListingSchema = z.object({}).merge(ItemDescriptionSchema).merge(PricingSchema);

export type CreateListingInputs = z.infer<typeof CreateListingSchema>;

/** Form */

export interface CreateListingFormProps {
  onSubmit: SubmitHandler<CreateListingInputs>;
  isOnboarding?: boolean;
}

export default function CreateListingForm({ onSubmit, isOnboarding }: CreateListingFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch,
    setValue,
  } = useForm<CreateListingInputs>({
    resolver: zodResolver(CreateListingSchema),
  });
  const { fields, append, remove } = useFieldArray({ control, name: "rules" });

  const pricing = watch("pricing");
  const rentalPrice = pricing && Object.values(pricing).filter((v) => !!v)[0];
  const commissionRate = /** user.account.commisionRate */ 0.2;
  const fee = rentalPrice && rentalPrice * commissionRate;
  const netPrice = rentalPrice - fee;

  /** Categories Query */
  const categoriesQuery = useGetAvailableCategoriesQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  const selectedCategoryId = watch("category")?.categoryId;
  const attributesQuery = useGetCategoryAttributesQuery(
    { categoryId: selectedCategoryId },
    {
      enabled: !!selectedCategoryId,
      refetchOnWindowFocus: false,
      onSuccess: function setAttributeFields(data) {
        const categoryAttributes = data.CategoryAttributes.map((a) => ({
          attributeId: a!.attributeId,
        }));

        // Overwrite the old attributes value to remove irrelevent attributes
        setValue("attributes", categoryAttributes);
      },
    }
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Section title="Item description">
        <LabelledInput
          label="Item Title"
          isError={!!errors.title}
          errorText={errors.title?.message}
          {...register("title")}
        />

        <LabelledInput
          label="Brand"
          isError={!!errors.brand}
          errorText={errors.brand?.message}
          {...register("brand")}
        />

        <Controller
          name="images"
          control={control}
          render={({ field, fieldState }) => (
            <Dropzone
              accept="image/*"
              getUploadParams={getUploadParams}
              onChangeStatus={(_file, status, allFiles) => {
                if (status === "done" || status === "removed") {
                  const uploadTokens = allFiles
                    .filter((f) => f.meta.status === "done")
                    .map((f) => JSON.parse(f.xhr!.response).token);

                  field.onChange(uploadTokens);
                }
              }}
              isError={fieldState.invalid}
              // @ts-ignore Incorrect RHF typings. This field does have errors.
              errorText={fieldState.error?.message}
              {...field}
            />
          )}
        />

        <Controller
          name="location"
          control={control}
          render={({ field, fieldState }) => (
            <AsyncCombobox
              {...field}
              value={
                field.value
                  ? {
                      label: `${field.value.locality} ${field.value.state} ${field.value.postcode}`,
                      value: serializeLocation(field.value),
                    }
                  : undefined
              }
              onChange={(e) =>
                field.onChange?.(e?.value ? deserializeLocation(e.value) : undefined)
              }
              label="Item Location"
              loadOptions={getLocalitySuggestion}
              isError={fieldState.invalid}
              errorText="Required"
              placeholder="Enter a suburb or postcode where this item is located"
            />
          )}
        />

        <Tip>
          <h3 tw="text-md font-medium">Item location tip</h3>
          <p tw="text-md">This is where your item pick up will be set up to show.</p>
        </Tip>

        <Controller
          name="category"
          control={control}
          render={({ field, fieldState }) => (
            <Combobox
              {...field}
              label="Item Category"
              options={
                categoriesQuery.data &&
                createCategoryGroups(categoriesQuery.data.AvailableCategories).map(
                  ({ categoryName, children }) => ({
                    label: categoryName,
                    options: children.map((child) => ({
                      label: child.path,
                      value: child.categoryId,
                    })),
                  })
                )
              }
              value={
                field.value
                  ? {
                      label: field.value.categoryName,
                      value: field.value.categoryId,
                    }
                  : undefined
              }
              onChange={(data) => {
                const newValue = data
                  ? {
                      categoryName: data.label,
                      categoryId: data.value,
                    }
                  : undefined;

                field.onChange(newValue);
              }}
              isError={fieldState.invalid}
              errorText="Required"
            />
          )}
        />

        {attributesQuery.data &&
          attributesQuery.data.CategoryAttributes.map((attribute, idx) => (
            <Controller
              key={attribute?.attributeId}
              name={`attributes.${idx}`}
              control={control}
              render={({ field }) => {
                const { attributeType, attributeName, selectOptions } = attribute!;

                const sharedProps = {
                  ...field,
                  label: attributeName,
                };

                const selectProps = {
                  ...sharedProps,

                  options: selectOptions.map((option) => ({
                    label: option,
                    value: option,
                  })),

                  ...(attributeType === AttributeType.SelectMulti && {
                    isMulti: true,
                    value: field.value?.valueStringArray?.map((v) => ({ label: v, value: v })),
                    onChange: (value: any) => {
                      field.onChange({
                        ...field.value,
                        valueStringArray: value?.map((v: any) => v.value),
                      });
                    },
                  }),

                  ...(attributeType === AttributeType.SelectSingle && {
                    value: { label: field.value?.valueString, value: field.value?.valueString },
                    onChange: (value: any) => {
                      field.onChange({
                        ...field.value,
                        valueString: value?.value,
                      });
                    },
                  }),
                };

                /**
                 * Select Types
                 */
                if (
                  attributeType === AttributeType.SelectMulti ||
                  attributeType === AttributeType.SelectSingle
                ) {
                  return <Combobox {...selectProps} />;
                }

                const inputProps = {
                  ...sharedProps,

                  value: field.value?.valueString,
                  onChange: (e: any) => {
                    field.onChange({
                      ...field.value,
                      valueString: e.target.value,
                    });
                  },

                  ...(attributeType === AttributeType.InputInt && {
                    type: "number",
                    value: field.value?.valueInt,
                    onChange: (e: any) => {
                      field.onChange({
                        ...field.value,
                        valueInt: Number(e.target.value ?? 0),
                      });
                    },
                  }),

                  ...(attributeType === AttributeType.InputFloat && {
                    type: "number",
                    value: field.value?.valueFloat,
                    onChange: (e: any) => {
                      field.onChange({
                        ...field.value,
                        valueFloat: Number(e.target.value ?? 0),
                      });
                    },
                  }),
                };

                /**
                 * Input Types
                 */

                return <LabelledInput {...inputProps} />;
              }}
            />
          ))}

        <Controller
          name="condition"
          control={control}
          render={({ field, fieldState }) => (
            <Combobox
              {...field}
              label="Condition"
              options={CONDITION_OPTIONS}
              value={CONDITION_OPTIONS.find((o) => o.value === field.value)}
              onChange={(e) => field.onChange(e?.value)}
              isError={fieldState.invalid}
              errorText="Required"
            />
          )}
        />

        {/* Keywords */}
        <div tw="flex flex-col gap-y-2">
          <p tw="mb-1 text-md font-medium">
            Add tags to make it easier for users to find your item.
          </p>

          <Controller
            name="keywords"
            control={control}
            render={({ field, fieldState }) => (
              <CreatableCombobox
                {...field}
                label="Item Tags"
                placeholder="Please type your tags here"
                // @ts-ignore ???
                isMulti
                value={field.value?.map((v) => ({ label: v, value: v }))}
                onChange={(e) => field.onChange((e as any)?.map((v: any) => v.value))}
                assistiveText="20 character limit"
                isError={fieldState.invalid}
                errorText="Required"
              />
            )}
          />
        </div>

        <div>
          <p tw="mb-1 text-md font-medium">Add a detailed description</p>
          <Textarea {...register("description")} rows={6} />
          <small tw="text-error">{errors.description?.message}</small>
        </div>

        <div tw="flex flex-col gap-y-2">
          <p tw="mb-1 text-md font-medium">
            Add rules for your rental <span tw="text-gray-400 font-normal">(optional)</span>
          </p>

          {fields.map((field, idx) => (
            <div tw="flex items-center gap-x-4" key={field.id}>
              <Input
                placeholder="e.g. Particular collection and drop-off times, Freshwater use only (max 120 characters)"
                {...register(`rules.${idx}.value`)}
                tw="flex-1"
              />
              <Button
                color="dark"
                fill="clear"
                size="small"
                shape="round"
                onClick={() => remove(idx)}
                tw="flex-initial"
              >
                <IonIcon slot="icon-only" icon={closeCircleOutline} />
              </Button>
            </div>
          ))}

          <Button
            color="success"
            fill="clear"
            size="small"
            onClick={() => append({})}
            tw="self-start"
          >
            <IonIcon slot="start" icon={addCircleOutline} />
            Add rule
          </Button>
        </div>
      </Section>

      <Section title="Price and availability">
        <Tip>
          <h3 tw="text-md font-medium">How does pricing work?</h3>

          <p tw="text-md">Set the refundable bond amount and a daily rental price</p>

          {rentalPrice && (
            <>
              <p tw="text-md font-medium">
                <span>Rental price</span>
                <span tw="float-right">{formatCurrency(rentalPrice)}</span>
              </p>

              <hr />

              <p tw="text-md font-medium">
                <span>You earn</span>
                <span tw="float-right">{formatCurrency(netPrice)}</span>
              </p>

              <p tw="text-sm">
                <span>Releaseit fees ({commissionRate * 100}%)</span>
                <span tw="float-right">{formatCurrency(fee)}</span>
              </p>
            </>
          )}
        </Tip>

        <div>
          <div tw="flex gap-x-3">
            <div tw="flex-1 flex items-center border-b border-gray-200">
              <label tw="font-medium">Refundable bond price</label>
            </div>

            <Input
              type="number"
              leftIcon={<span tw="text-sm font-medium">$</span>}
              tw="flex-initial w-28"
              defaultValue={0}
              {...register("bondPrice", { setValueAs: (v) => Number(v) })}
            />
          </div>
          <small tw="text-error">{errors.bondPrice?.message}</small>
        </div>

        <Controller
          name="pricing"
          control={control}
          render={({ field: { ref, ...field }, fieldState }) => (
            <Pricing
              isError={fieldState.invalid}
              errorText={
                // @ts-ignore Incorrect RHF typings. This field does have errors.
                fieldState.error?.message ||
                Object.values(fieldState.error || {}).find((error) => !!error?.message)?.message
              }
              {...field}
            />
          )}
        />
      </Section>

      <div tw="flex flex-col space-y-4 px-4 pt-6 lg:(px-0)">
        <p tw="text-sm">
          By proceeding, you agree to the{" "}
          <a href="/page/terms-of-use" tw="text-mid-green no-underline">
            Terms of Use
          </a>{" "}
          and the collection, use and disclosure of your personal information in accordance with our{" "}
          <a href="/page/privacy-policy" tw="text-mid-green no-underline">
            Privacy Policy
          </a>
          .
        </p>

        <div tw="flex flex-col gap-2 lg:flex-row">
          <Button type="submit" disabled={isSubmitting}>
            {isOnboarding ? "Next" : "Publish"}
          </Button>

          <Button fill="clear" color="dark">
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
}

/** Internal Components */

const Section: React.FC<{ title: string }> = ({ title, children, ...props }) => (
  <div
    tw="px-4 py-6 bg-gradient-to-b from-off-white to-white lg:(background[none] px-0)"
    {...props}
  >
    <h2 tw="text-lg font-medium mb-4 pb-2 border-b border-gray-200">{title}</h2>

    <div tw="flex flex-col gap-y-3">{children}</div>
  </div>
);

const Tip: React.FC = ({ children, ...props }) => (
  <div tw="flex flex-col gap-y-2 p-3 bg-gray-100 rounded-lg" {...props}>
    {children}
  </div>
);

/** Utils */

export const getLocalitySuggestion = debounce((input: string, callback: any) => {
  if (input.length < 3) return callback([]);

  useLocalityAutocompleteQuery
    .fetcher({ localityPartial: input })()
    .then((res) => {
      callback(
        (res.LocalityAutocomplete ?? []).map((l) => ({
          label: `${l?.locality} ${l?.state} ${l?.postcode}`,
          value: JSON.stringify(l),
        }))
      );
    });
}, 500);

export const getUploadParams: IDropzoneProps["getUploadParams"] = async ({ file }) => {
  const { RequestImageToken } = await useRequestImageTokenQuery.fetcher()();
  const { token } = RequestImageToken[0];

  const body = new FormData();
  body.append("image", file);
  body.append("token", token);

  return { url: process.env.REACT_APP_IMAGE_SERVER as string, body };
};

export function serializeLocation(location: ListingLocation) {
  return JSON.stringify(location);
}

export function deserializeLocation(location: string) {
  return JSON.parse(location) as ListingLocation;
}

export function createCategoryGroups(
  categories: GetAvailableCategoriesQuery["AvailableCategories"]
) {
  // Find the top level categories
  const topLevelCategories = categories.filter((category) => category.depth === 1);

  // Find the subcategories (depth > 1) and sort them alphabetically by path
  const subcategories = categories
    .filter((category) => category.depth > 1)
    .sort((a, b) => a.path.localeCompare(b.path));

  // Create the category groups
  const groups = topLevelCategories.map((parent) => {
    const children = subcategories
      // Iterate through all subcategories and find the ones that belong to this parent.
      .filter((category) => category.path.startsWith(parent.path))
      // We also need to format the path.
      .map((category) => ({ ...category, path: category.path.split(",").slice(1).join(" > ") }));

    return {
      ...parent,
      children,
    };
  });

  return groups;
}
