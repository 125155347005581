import { createGlobalStyle } from "styled-components";
import tw, { GlobalStyles as BaseStyles, screen } from "twin.macro";

const CustomStyles = createGlobalStyle`
/* #region Ionic Fixes */

.plt-ios.plt-iphone ion-content::part(scroll) {
  ${tw`pb-12`}
}

/*
  These styles make it possible to position the tabbar beneath the router outlet
  while using a tabbar without the Tab container.
 */
ion-router-outlet {
  position: unset;

  ${tw`flex-grow`}
}

ion-modal.auth-modal {
  --min-height: 0px;

  ${screen`md`} {
  --max-width: 470px;
  --border-radius: 10px;
  }
}

ion-datetime[size="cover"] {
  min-width: 0;
}

/* #endregion */

a {
  ${tw`text-gray-400 underline hover:text-gray-600`}
}


/* Headings (Taken from Zeplin) */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Aeonik;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--black);
}

h1 {
  font-size: 50px;
  line-height: 1.2;
}

h2 {
  font-size: 40px;
  line-height: 1.2;
}

h3 {
  font-size: 32px;
  line-height: 1.25;
}

h4 {
  font-size: 29px;
  line-height: 1.1;
}

h5 {
  font-size: 26px;
  line-height: 1.08;
}

h6 {
  font-size: 11px;
  line-height: 1.36;
}
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
