import React from "react";
import { useIonToast } from "@ionic/react";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

import { useCreateListingMutation } from "@app/generated/graphql";
import { dollarToCents, pricingAsCents } from "@app/utils/pricing";
import { withDefaultLayout } from "@app/layouts/default-layout";
import CreateListingForm, {
  CreateListingFormProps,
} from "@app/components/listing/CreateListingForm";
import { gql } from "graphql-request";
import tw from "twin.macro";

export const CREATE_LISTING_MUTATION = gql`
  mutation CreateListing(
    $title: String!
    $description: String!
    $keywords: [String!]!
    $pricing: PricingInput!
    $bondPrice: Float!
    $condition: Condition!
    $rules: [String!]!
    $images: [ImageInput!]!
    $categoryId: String!
    $brand: String
    $attributes: [AttributeValueInput!]!
    $location: ListingLocationInput
  ) {
    CreateListing(
      title: $title
      description: $description
      keywords: $keywords
      pricing: $pricing
      bondPrice: $bondPrice
      condition: $condition
      rules: $rules
      images: $images
      categoryId: $categoryId
      brand: $brand
      attributes: $attributes
      location: $location
    ) {
      __typename

      ... on CreateListingSuccess {
        listing {
          listingId
        }
      }

      ... on GenericError {
        errorCode
        errorMessage
      }
    }
  }
`;

export const GET_STRIPE_TRANSFERS_STATUS_QUERY = gql`
  query GetStripeTransfersStatus {
    GetStripeTransfersStatus
  }
`;

const OnboardingCreateListing: React.FC = () => {
  const history = useHistory();
  const [toast] = useIonToast();

  /** Create Mutation */
  const createMutation = useCreateListingMutation();
  const onSubmit: CreateListingFormProps["onSubmit"] = async ({ ...data }) => {
    try {
      const { CreateListing: res } = await createMutation.mutateAsync({
        ...data,
        categoryId: data.category.categoryId,
        pricing: pricingAsCents(data.pricing),
        bondPrice: dollarToCents(data.bondPrice),
        images: data.images.map((i, idx) => ({ sortOrder: idx + 1, imageToken: i })),
        rules: data.rules.map((r) => r.value),
      });

      switch (res?.__typename) {
        case "GenericError":
          throw new Error(res.errorCode);

        case "CreateListingSuccess":
          ReactGA.event({
            category: "listing",
            action: "created",
            nonInteraction: false,
          });
          ReactPixel.track("listing.created", {});

          window.location.replace("/onboarding/identity");
          break;

        default:
          throw new Error("Something went wrong. Please try again.");
      }
    } catch (error) {
      // The below has a type error so I made it support any
      // toast({ color: "danger", message: error.message });
      toast({ color: "danger", message: JSON.stringify(error) });
    }
  };

  return (
    <div tw="divide-y divide-gray-200 lg:(max-w-xl divide-none mx-auto mt-16)">
      <div tw="flex flex-col items-start lg:(flex-row items-center) gap-x-[58px] gap-y-4 my-4 mx-4 lg:mx-0">
        <Step label="Create Listing" count={1} active />
        <Step label="Personal Details" count={2} />
        <Step label="Upload Documents" count={3} />
      </div>

      <h1 tw="text-base font-medium text-center py-4 lg:(text-3xl text-left py-0)">
        List your first item
      </h1>

      {/* Instructions */}
      <div>
        <p tw="container px-4! py-6 text-md lg:(px-0!)">
          It's free to list an item and it will be visible to the public as soon as our team
          approves your listing.
        </p>
      </div>

      {/* Force React to reconcile on location since ionic doesn't unmount pages (this is some wild fix 🤪) */}
      <CreateListingForm key={history.location.pathname} isOnboarding onSubmit={onSubmit} />
    </div>
  );
};

export default withDefaultLayout(OnboardingCreateListing);

function Step({ label, active, count }: { label: string; active?: boolean; count: number }) {
  return (
    <div tw="flex-1 flex items-center gap-x-4">
      <div
        css={[
          tw`h-[42px] w-[42px] flex-shrink-0 rounded-full bg-off-white text-gray-700 flex items-center justify-center font-bold`,
          active && tw`border-2 border-primary`,
        ]}
      >
        {count}
      </div>
      <span tw="font-medium text-gray-800" css={{ whiteSpace: "pre-wrap" }}>
        {label}
      </span>
    </div>
  );
}
