import React from "react";
import { IonIcon } from "@ionic/react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import tw, { styled } from "twin.macro";

import { useGetNavigationQuery } from "@app/generated/graphql";
import { Button, ButtonLink } from "@app/components/form";
import { when } from "@app/styles/utils";

/* Icons */
import { ReactComponent as AppStore } from "@app/assets/icons/app-store.svg";
import { ReactComponent as GooglePlay } from "@app/assets/icons/google-play.svg";
import { ReactComponent as Logo } from "@app/assets/icons/releaseit-green.svg";
import { logoFacebook, logoInstagram, logoTwitter, logoLinkedin } from "ionicons/icons";
import { chevronDownOutline } from "ionicons/icons";
import { gql } from "graphql-request";

export const GET_NAVIGATION_QUERY = gql`
  query GetNavigation {
    Navigation {
      promoLinks {
        title
        href
      }
      footer {
        title
        links {
          title
          href
        }
      }
      categories {
        title
        href
      }
    }
  }
`;

interface IFooterLink {
  title: string;
  href: string;
}

interface IFooterGroup {
  title: string;
  links: IFooterLink[];
}

const Footer: React.FC<{ className?: string }> = ({ className }) => {
  const location = useLocation();
  const query = useGetNavigationQuery({}, { staleTime: 1000 * 60 * 30 });
  const footerGroups: IFooterGroup[] = useMemo(() => {
    return query.data?.Navigation.footer.map((fg) => ({ title: fg.title, links: fg.links })) || [];
  }, [query.data]);

  if (query.isLoading) {
    return null;
  }

  return (
    <FooterWrap
      id="footer"
      isChatPage={location.pathname.split("/")[1] === "chat" ? true : false}
      className={className}
    >
      {/* <footer tw="mt-16 bg-gray-800 text-white" className={className}> */}
      {/* Top Section */}
      <div tw="py-6 lg:(p-4 bg-black)">
        {/* Logo and Socials */}
        <div tw="container flex flex-col items-center justify-between gap-y-7 lg:flex-row">
          <Logo height={50} width={205} />

          {/* Social Buttons */}
          <div tw="flex items-center gap-x-4">
            <a
              tw="bg-primary h-12 w-12 rounded-full flex justify-center items-center"
              href="https://www.instagram.com/releaseitau/"
              target="_blank"
              rel="noreferrer"
            >
              <IonIcon slot="icon-only" tw="text-black h-6 w-6" icon={logoInstagram} />
            </a>
            <a
              tw="bg-primary h-12 w-12 rounded-full flex justify-center items-center"
              href="https://twitter.com/releaseitau"
              target="_blank"
              rel="noreferrer"
            >
              <IonIcon slot="icon-only" tw="text-black h-6 w-6" icon={logoTwitter} />
            </a>
            <a
              tw="bg-primary h-12 w-12 rounded-full flex justify-center items-center"
              href="https://www.facebook.com/releaseitau"
              target="_blank"
              rel="noreferrer"
            >
              <IonIcon slot="icon-only" tw="text-black h-6 w-6" icon={logoFacebook} />
            </a>
            <a
              tw="bg-primary h-12 w-12 rounded-full flex justify-center items-center"
              href="https://www.linkedin.com/company/releaseit/"
              target="_blank"
              rel="noreferrer"
            >
              <IonIcon slot="icon-only" tw="text-black h-6 w-6" icon={logoLinkedin} />
            </a>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div tw="container flex flex-col gap-y-8 py-9 lg:py-6">
        <MobileLinkGroups groups={footerGroups} className="ion-hide-lg-up" />
        <DesktopLinkGroups groups={footerGroups} className="ion-hide-lg-down" />

        <hr tw="hidden lg:block opacity-10" />

        {/* Stores */}
        {/* <div tw="flex justify-center gap-x-4 lg:hidden">
          <a href="/" target="_blank">
            <AppStore />
          </a>
          <a href="/" target="_blank">
            <GooglePlay />
          </a>
        </div> */}

        {/* Legal */}
        <div tw="flex flex-col items-center gap-4 text-sm md:items-start">
          <p tw="px-2 text-center md:px-0 md:text-left">
            &ldquo;We don&apos;t buy it&rdquo; and &ldquo;We don&apos;t buy it. You shouldn&apos;t
            either&rdquo; are trademarks of Releaseit Holdings Pty Ltd.
          </p>

          <p tw="font-medium">©2021 Releaseit Pty Ltd</p>

          {/* <div tw="flex gap-x-2">
            <a href="/">Terms & Conditions</a>
            <span>|</span>
            <a href="/">Privacy</a>
            <span>|</span>
            <a href="/">Sitemap</a>
          </div> */}
        </div>
      </div>
    </FooterWrap>
  );
};

const DesktopLinkGroups: React.FC<{
  groups: IFooterGroup[];
  className?: string;
}> = ({ groups, className }) => (
  <LinkGroups className={className}>
    {groups.map((group, idx) => (
      <Group key={idx}>
        <h3 tw="text-sm text-gray-400 font-medium">{group.title}</h3>

        <div tw="flex flex-col gap-y-1 items-start">
          {group.links.map((link: IFooterLink) => {
            if (link.href.startsWith("https://www.releaseit.com.au")) {
              return (
                <a href={link.href} key={link.href} tw="text-white no-underline hover:underline">
                  {link.title}
                </a>
              );
            } else if (link.href.startsWith("http")) {
              return (
                <a
                  href={link.href}
                  key={link.href}
                  tw="text-white no-underline hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {link.title}
                </a>
              );
            } else {
              return (
                <a href={link.href} key={link.href} tw="text-white  no-underline hover:underline">
                  {link.title}
                </a>
              );
            }
          })}
        </div>
      </Group>
    ))}
  </LinkGroups>
);

const MobileLinkGroups: React.FC<{
  groups: IFooterGroup[];
  className?: string;
}> = ({ groups, className }) => {
  const [activeMenu, setActiveMenu] = React.useState(-1);

  const handleToggle = (index: number) => {
    if (activeMenu === index) {
      return setActiveMenu(-1);
    }
    setActiveMenu(index);
  };

  return (
    <MLinkGroups tw="text-left" className={className}>
      {groups.map((group, idx) => (
        <MGroup key={idx} onClick={() => handleToggle(idx)} isActive={activeMenu === idx}>
          <TickBox title={group.title} idx={idx} key={`${slugify(group.title)}-${idx}`} />
          {activeMenu === idx ? (
            <MLinkItem key={slugify(group.title)}>
              {group.links.map((link: IFooterLink) => {
                return link.href.startsWith("http") ? (
                  <a href={link.href} key={link.href} tw="text-white no-underline hover:underline">
                    {link.title}
                  </a>
                ) : (
                  <a href={link.href} key={link.href} tw="text-white no-underline hover:underline">
                    {link.title}
                  </a>
                );
              })}
            </MLinkItem>
          ) : null}
        </MGroup>
      ))}
    </MLinkGroups>
  );
};
//#region Styles

interface IStyleProps {
  isActive?: boolean;
  isChatPage?: boolean;
}

const FooterWrap = styled.footer<IStyleProps>`
  ${tw`mt-16 bg-gray-800 text-white`}
  ${(p) => when(p.isChatPage)} {
    ${tw`hidden`}
  }
`;

const LinkGroups = styled.div(() => [
  tw`
    flex gap-7 mb-8
    text-sm
  `,
]);

const Group = styled.div(() => [
  tw`
    flex flex-col flex-auto gap-3
  `,
]);

const MLinkGroups = styled.div`
  ${tw`flex px-8 mb-8
    text-sm flex-col`}
`;

const MGroup = styled.div<IStyleProps>`
  ${(p) => when(p.isActive)} {
    ion-icon {
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }

    -webkit-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out;
  }
`;

const MLinkItem = styled.div`
  ${tw`flex flex-col text-white gap-y-1 
    items-start overflow-hidden h-full`}

  -webkit-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
`;

const TickBox = (props: any) => {
  const slug = slugify(props.title);
  const id = `${slug}-${props.idx}`;
  return (
    <>
      <label
        htmlFor={id}
        tw="flex justify-between items-center py-3 mb-2 border-b border-opacity-50 border-gray-200"
      >
        <h3 tw="text-sm text-white">{props.title}</h3>
        <IonIcon icon={chevronDownOutline} slot="icon-only" />
      </label>
    </>
  );
};

const slugify = (text: string) => {
  return text
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
};

//#endregion

export default Footer;
