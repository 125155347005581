import React from "react";
import { IonButton } from "@ionic/react";
import tw, { styled, theme } from "twin.macro";
import { StyledComponent } from "styled-components";

type IonButtonProps = React.ComponentProps<typeof IonButton>;
export interface IButtonProps extends IonButtonProps {
  /**
   * Sets the color of the button.
   *
   * @note `light` should be used as a secondary colour.
   */
  color?: "primary" | "light" | "dark" | "medium" | "off-white" | "success" | "warning" | "danger";

  size?: IonButtonProps["size"];

  fill?: IonButtonProps["fill"];

  disabled?: IonButtonProps["disabled"];

  expand?: IonButtonProps["expand"];

  shape?: IonButtonProps["shape"];

  /**
   * onClick handler.
   */
  onClick?: () => void;
}

/**
 * Custom built button element used throughout the application.
 */
const Button: StyledComponent<React.FC<IButtonProps>, any> = styled(IonButton)`
  --padding-start: ${theme`space.6`};
  --padding-end: ${theme`space.6`};
  --border-radius: ${theme`borderRadius.md`};
  --box-shadow: ${theme`boxShadow.none`};

  ${tw`m-0 capitalize`}

  /* Color */

  &[color="light"] {
    --border-width: ${theme`borderWidth.DEFAULT`};
    --border-color: ${theme`colors.gray.600`};
    --border-style: solid;
  }

  /* Size */

  &[size="small"] {
    --padding-start: ${theme`space.4`};
    --padding-end: ${theme`space.4`};

    ${tw`h-8`}
  }

  &,
  &[size="default"] {
    ${tw`h-12`}
  }

  &[size="large"] {
    ${tw`h-14`}
  }

  /* Round */

  &[shape="round"] {
    --border-radius: ${theme`borderRadius.full`};
  }

  /* Icon Button */

  &[shape="round"].button-has-icon-only:not([expand]) {
    --padding-start: 0;
    --padding-end: 0;

    &[size="small"] {
      ${tw`w-8`}
    }

    &,
    &[size="default"] {
      ${tw`w-12`}
    }

    &[size="large"] {
      ${tw`w-14`}
    }
  }
`;

Button.defaultProps = {
  color: "primary",
  size: "default",
};

export default Button;
